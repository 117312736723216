.toast-container {
  display: flex !important;
  align-items: center;
  background-color: rgba(50, 50, 50, 0.95);
  color: white !important;
  opacity: 0.85;
  padding-left: 15px !important;
  border-radius: 5px !important;
  button {
    color: white !important;
    opacity: 1;
  }
  .message {
    margin-left: 10px;
  }
}

.mc-error-message {
  background-color: #fff !important;
  opacity: 1 !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;

  button {
    display: none;
  }

  .message {
    color: #000 !important;
  }
  .icon {
    color: #dc3545;
    font-size: 1rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
}
